import React, { useRef, useEffect } from "react"
import { Box } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactPlayer from "react-player"
import { useIsInViewport } from "../../utils/isInViewport"

const VideoEmbed = ({ block }) => {
  const { video, autoplay } = block
  const videoRef = useRef()
  const isInViewport1 = useIsInViewport(videoRef)

  useEffect(() => {
    // 👇️ listen for changes
    console.log(isInViewport1)
  }, [isInViewport1])

  return (
    <Box
      ref={videoRef}
      sx={{
        backgroundColor: "dark",
        position: "relative",
        height: "100%",
        width: "100%",
        ".gatsby-image-wrapper": { width: "100%", height: "100%" },
        overflow: "hidden",
        ".react-player": {
          position: "absolute",
          top: 0,
        },
        video: {
          maxHeight: "100vh",
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      }}
    >
      {video.gatsbyImageData ? (
        <GatsbyImage image={video.gatsbyImageData} />
      ) : autoplay && video.url ? (
        <video
          title={"background-video"}
          id=""
          class=""
          loop
          autoPlay
          muted
          playsinline="playsinline"
        >
          <source src={video.url} type={video.mimeType} />
        </video>
      ) : (
        video.url && (
          <Box
            sx={{
              position: "relative",
              paddingTop: "56.25%",
              ".react-player__shadow" :{
                display: video.customData.thumbnailUrl ? "none!important" : "flex"
              }
            }}
          >
            <ReactPlayer
              className="react-player"
              url={
                video.video.streamingUrl ? video.video.streamingUrl : video.url
              }
              controls={!autoplay}
              playing={true}
              autoPlay={true}
              light={autoplay ? false : video.customData.thumbnailUrl ? video.customData.thumbnailUrl : video.video.thumbnailUrl + "?time=45"}
              width="100%"
              height="100%"
              playsinline="playsinline"
            />
            {console.log(video)}
          </Box>
          
          // <video
          //   title={"background-video"}
          //   id=""
          //   class=""
          //   loop
          //   autoPlay={autoplay}
          //   controls={!autoplay}
          //   muted
          //   playsinline="playsinline"
          // >
          //   <source src={video.url} type={video.mimeType} />
          // </video>
        )
      )}
    </Box>
  )
}

export default VideoEmbed
