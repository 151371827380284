import React from "react"
import { Box, Heading, Grid, Container, Text } from "theme-ui"
import ErrorIcon from "../../assets/images/XXO.inline.svg"

const MonthNotAvailable = () => {
  return (
    <Box>
      <Container sx={{ pt: ["60px", "60px", 0], maxWidth: "900px" }}>
      <Heading as="h1" variant="h2" sx={{ color: "text", mb:[5,5], mt:[0], textAlign:"center" }}>
                Non così veloce!
              </Heading>
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            pb: [4],
          }}
        >
          <Box
            sx={{
              svg: {
                width: "100%",
                height: "auto",
              },
            }}
          >
            <ErrorIcon />
          </Box>
        </Box>
        <Heading as="p" variant="p" sx={{ fontSize: [4, 6, 8] }}>
          L'innovazione corre, ma tu stai bruciando le tappe. Questo mese sarà
          disponibile al momento giusto: nel frattempo lasciati ispirare dai
          contenuti dei mesi scorsi.
        </Heading>
      </Container>
    </Box>
  )
}

export default MonthNotAvailable
