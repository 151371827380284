import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Box, Container, Heading, Grid, Text } from "@theme-ui/components"
import loadable from "@loadable/component"
import Layout from "../layouts/Layout"
import { getPagePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import MonthHero from "../layouts/Hero/Month"
import { useFavicon } from "../hooks/useFavicon"
import TitleAndBody from "../components/TitleAndBody"
import VideoEmbed from "../components/VideoEmbed"
import Image from "../components/Image"
import ColorPalette from "../components/ColorPalette"
import DoubleImageVideo from "../components/DoubleImageVideo"
import { isMonth } from "../utils/currentMonth"
import MonthNotAvailable from "../components/MonthNotAvailable"
import NewsCollection from "../components/NewsCollection"

const Page = ({ data: { page, site, news } }) => {
  const [isCurrentPastMonth, setisCurrentPastMonth] = useState(null)

  const favicon = useFavicon().site.faviconMetaTags

  const pageAllSlugLocales = page._allSlugLocales.sort(function (a, b) {
    return site.locales.indexOf(a.locale) - site.locales.indexOf(b.locale)
  })

  useEffect(() => {
    setisCurrentPastMonth( isMonth(page.title.split(" ")[0]))
  }, [])

  const i18nPaths = pageAllSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getPagePath(page, locale.locale),
    }
  })

  return isCurrentPastMonth !== null && (
    <Layout
      locale={page.locale}
      page={{
        pageTitle: page.title,
        type: "month",
        currentPastMonth: isCurrentPastMonth,
      }}
      i18nPaths={i18nPaths}
    >
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      {isCurrentPastMonth === true ? (
        <>
          <Box sx={{ backgroundColor: "dark" }}>
            <MonthHero page={page} image={page.heroImage} />
            {page.content.map(
              block =>
                block.model &&
                block.model.apiKey && (
                  <Box as="section" key={block.id}>
                    {block.model.apiKey === "title_and_body" && (
                      <TitleAndBody block={block} theme={"dark"} />
                    )}
                    {block.model.apiKey === "double_image_video" && (
                      <DoubleImageVideo block={block} />
                    )}
                    {block.model.apiKey === "image" && <Image block={block} />}
                    {block.model.apiKey === "video" && (
                      <VideoEmbed block={block} />
                    )}
                    {block.model.apiKey === "color_palette" && (
                      <ColorPalette block={block} />
                    )}
                    {block.model.apiKey === "news_collection" &&
                      news &&
                      news.nodes.length > 0 && (
                        <NewsCollection
                          block={block}
                          news={news.nodes}
                          theme={"dark"}
                        />
                      )}
                  </Box>
                )
            )}
          </Box>
        </>
      ) : isCurrentPastMonth === false && (
        <MonthNotAvailable />
      )}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query MonthQueryQuery($id: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    news: allDatoCmsNews(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      limit: 3
    ) {
      nodes {
        ...NewsDetails
        ...NewsAllSlugLocales
        ...NewsMeta
      }
    }
    page: datoCmsPage(id: { eq: $id }) {
      ...PageDetails
      ...PageTreeParent
      ...AllSlugLocales
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsTitleAndBody {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsDoubleImageVideo {
          media {
            url
            mimeType
            video {
              streamingUrl
              thumbnailUrl
            }
            gatsbyImageData(
              width: 1920
              placeholder: NONE
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsNewsCollection {
          title
          model {
            apiKey
          }
        }
        ... on DatoCmsImage {
          image {
            url
            gatsbyImageData(
              width: 1920
              placeholder: NONE
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsVideo {
          video {
            url
            video {
              streamingUrl
              thumbnailUrl
            }
            customData
          }
          autoplay
          model {
            apiKey
          }
        }
        ... on DatoCmsColorPalette {
          colors {
            ... on DatoCmsColor {
              description
              color {
                red
                green
                blue
                alpha
                rgb
                hex
              }
            }
          }
          model {
            apiKey
          }
        }
      }
    }
  }

  fragment RichContent on DatoCmsRichContent {
    title
    label
    subtitle
    image {
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
    }
    body {
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
        ... on DatoCmsEmbed {
          id: originalId
          ...EmbedDetails
        }
        ... on DatoCmsNumbersGroup {
          id: originalId
          numbers {
            legend
            float
            suffix
            prefix
          }
        }
      }
      links {
        __typename
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale
          model {
            apiKey
          }
          link {
            ... on DatoCmsBlogPage {
              ...BlogDetails
            }
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
            ... on DatoCmsNews {
              ...NewsDetails
              ...NewsAllSlugLocales
            }
          }
        }
      }
      value
    }
    link {
      ... on DatoCmsInternalLink {
        id
        anchor
        locale
        model {
          apiKey
        }
        link {
          ... on DatoCmsBlogPage {
            ...BlogDetails
          }
          ... on DatoCmsPage {
            ...PageDetails
            ...PageTreeParent
            ...AllSlugLocales
          }
          ... on DatoCmsNews {
            ...NewsDetails
            ...NewsAllSlugLocales
          }
        }
      }
      ... on DatoCmsExternalLink {
        id
        anchor
        url
        model {
          apiKey
        }
      }
    }
    model {
      apiKey
    }
  }

  fragment AllSlugLocales on DatoCmsPage {
    _allSlugLocales {
      value
      locale
    }
  }

  fragment EmbedDetails on DatoCmsEmbed {
    title
    code
    fullWidth
    model {
      apiKey
    }
  }

  fragment PageTreeParent on DatoCmsPage {
    treeParent {
      id
      title
      slug
      root
      locale
      ...AllSlugLocales
      treeParent {
        id
        title
        slug
        root
        locale
        ...AllSlugLocales
      }
    }
  }
`
