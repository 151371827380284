import React, { useState, useMemo, useEffect } from "react"

export function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false)

  useEffect(() => {
    if (ref.current) {
      const ioCallback = ([entry]) => {
        setIsIntersecting(entry.isIntersecting)
      }

      const observer = new IntersectionObserver(ioCallback)
      observer.observe(ref.current)
    }
  }, [])

  return isIntersecting
}
