import React, { useRef, useEffect, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box, Container, Grid } from "theme-ui"
import { useIsInViewport } from "../../utils/isInViewport"
import ReactPlayer from "react-player"

const DoubleImageVideo = ({ block }) => {
  const { media } = block
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const [autoplay, setAutoplay] = useState(true)

  const videoRef = useRef()
  const isInViewport1 = useIsInViewport(videoRef)

  useEffect(() => {
    // 👇️ listen for changes
    if (isInViewport1 && !isVideoPlaying) {
      setIsVideoPlaying(true)
    }
  }, [isInViewport1])

  return (
    <Box sx={{ position: "relative" }}>
      <Grid columns={["1fr", "1fr", "1fr 1fr"]} gap={[0]}>
        {media.map(
          image =>
            image && (
              <Box
                sx={{
                  position: "relative",
                  height: "100%",
                  width: "100%",
                  ".gatsby-image-wrapper": { width: "100%", height: "100%" },
                  overflow: "hidden",
                  video: {
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                }}
              >
                {image.gatsbyImageData ? (
                  <GatsbyImage image={image.gatsbyImageData} />
                ) : (
                  image.url && (
                    <Box ref={videoRef} sx={{
                      height:"100%",
                      
                    }} >
                      <ReactPlayer
                        muted={true}
                        className="react-player"
                        url={
                          image.video.streamingUrl
                            ? image.video.streamingUrl
                            : image.url
                        }
                        controls={false}
                        playing={isVideoPlaying}
                        loop={true}
                        width="100%"
                        height="100%"
                        playsinline="playsinline"
                      />
                    </Box>
                  )
                )}
              </Box>
            )
        )}
      </Grid>
    </Box>
  )
}

export default DoubleImageVideo
