import React from "react"
import { Box, Grid, Text, Heading } from "theme-ui"
import { keyframes } from "@emotion/react"
import { getColor } from "@theme-ui/color"
import theme from "../../gatsby-plugin-theme-ui"

const ColorpPalette = ({ block }) => {
  const { colors } = block

  const firstChunk = [colors[0], colors[1]]
  const secondChunk = [colors[2], colors[3]]

  const chunks = [firstChunk, secondChunk]

  const fadeIn = keyframes({
    "0%": { opacity: 0 },
    "25%": { opacity: 0 },
    "50%": { opacity: 1 },
    "100%": { opacity: 1 },
  })
  const fadeOut = keyframes({
    "0%": { opacity: 1 },
    "25%": { opacity: 1 },
    "50%": { opacity: 0 },
    "100%": { opacity: 0 },
  })

  const colorTransition1 = keyframes({
    "0%": { backgroundColor: colors[0].color.hex },
    "25%": { backgroundColor: colors[0].color.hex },
    "50%": { backgroundColor: colors[1].color.hex },
    "100%": { backgroundColor: colors[1].color.hex },
  })

  const colorTransition2 = keyframes({
    "0%": { backgroundColor: colors[2].color.hex },
    "25%": { backgroundColor: colors[2].color.hex },
    "50%": { backgroundColor: colors[3].color.hex },
    "100%": { backgroundColor: colors[3].color.hex },
  })

  console.log(colors[0])

  return (
    <Box>
      <Grid columns={["1fr", "1fr", "1fr 1fr"]} gap={[0]}>
        {chunks.map((bigchunk, index) => (
          <Box
            sx={{
              position: "relative",
              backgroundColor: `${colors[index === 0 ? 0 : 2].hex}`,
              animation: `${
                index === 0 ? colorTransition1 : colorTransition2
              } 6s linear infinite alternate`,
            }}
          >
            <Box
              sx={{
                opacity: 0,
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                opacity: 1,
                zIndex: -1,
                py: [9],
              }}
            >
              <Text
                as="div"
                variant="h2"
                dangerouslySetInnerHTML={{ __html: colors[0].description }}
              />
            </Box>
            {bigchunk.map((chunk, index) => (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  maxWidth:"640px",
                  width:"100%",
                  transform: "translate(-50%,-50%)",
                  opacity: index === 0 ? 1 : 0,
                  animation: `${
                    index === 0 ? fadeOut : fadeIn
                  } 6s linear infinite alternate`,
                  color: chunk.color.hex === "#FFFFFF" ? "primary" : "light",
                }}
              >
                <Text
                  as="div"
                  variant="h2"
                  sx={{
                    color: chunk.color.hex == "#ffffff" ? "primary" : "light",
                    p: {
                      p:[4],
                      fontWeight: "400",
                      my: [0, 0],
                      "-webkit-text-stroke":
                        chunk.color.hex == "#ffffff"
                          ? "1px #0072CE"
                          : "1px #FFFFFF",
                      color: "transparent",
                      fontFamily: "body",
                      em: {
                        "-webkit-text-stroke": "unset",
                        color:
                          chunk.color.hex == "#ffffff" ? "primary" : "light",
                        fontStyle: "normal",
                        fontFamily: "body",
                      },
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: chunk.description }}
                />
              </Box>
            ))}
          </Box>
        ))}
      </Grid>
    </Box>
  )
}

export default ColorpPalette
