import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useRef } from "react"
import { Box, Container, Heading, video } from "theme-ui"
import Breadcrumbs from "../../../components/Breadcrumbs"

const MonthHero = ({ page, image }) => {
  const videoRef= useRef()

  const handleToggleMute = () => {
      let video = videoRef.current;
      video.muted = false;
  }
  

  return (
  <Box
    sx={{
      position: "relative",
      backgroundColor: "primary",
      minHeight: ["auto","auto"],
      backgroundColor:"dark",
      zIndex:0,
      "&:after":{
        content:"''",
        position:"absolute",
        top:0,
        left:0,
        backgroundColor:"overlay",
        width:"100%",
        height:"100%",
      }
    }}
  >
    {image && (
      <Box
        sx={{
          position: "relative",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          ".gatsby-image-wrapper": { width: "100%" },
          overflow: "hidden",
          video: {
            width: "100%",
            height: ["auto"],
            objectFit: "cover",
          },
        }}
      >
        {image.gatsbyImageData ? (
          <GatsbyImage image={image.gatsbyImageData} />
        ) : (
          image.url && (
            <video
              onClick={handleToggleMute}
              ref={videoRef}
              title={"background-video"}
              id=""
              class=""
              autoPlay
              muted
              playsinline="playsinline"
            >
              <source src={image.url} type={image.mimeType} />
            </video>
          )
        )}
      </Box>
    )}
  </Box>)
}

export default MonthHero
